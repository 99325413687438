body {
    padding-bottom: 30%;
}

header {
	margin-top: -2%;
	margin-left: -2%;
	margin-right: -2%;
	padding-top: 2%;
	padding-left: 3%;
	padding-right: 2%;
	padding-bottom: 1%;
}

.headerText {
	font-size: 1.3em;
}

.patreon {
	font-size: 1.3em;
	position:absolute;
	right: 1%;
	top: 1%;
}

.title {
	margin:auto;
	align:center;
	text-align:center;
	padding: 1.5%;
}


.paragraph {
	margin:auto;
	align:center;
}

.texts {
  margin:auto;
	align:center;
	text-align:center;
}

.texts h3 a {
	padding:0.5%;
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .paragraph {
		width: 90%;
  }

	.texts {
		width: 90%;
	}
}

@media screen and (min-width: 600px) and (max-width: 1200px) {
  .paragraph {
		width: 80%;
  }

	.texts {
		width: 70%;
	}
}

@media screen and (min-width: 1200px) {
  .paragraph {
		width: 70%;
  }

	.texts {
		width: 50%;
	}
}

.token {
	cursor: pointer;
	font-size: 1.7em;
}

.token:hover {
	color: #FF55FF;
}

.tokenSelected {
	color: #DD33DD;
}

.paragraphNum {
	font-size: 1.7em;
}


.annotation {
	background-color:#EEEEEE;
	padding: 1.5%;
	border-radius: 2%;
	box-shadow: 2px 2px;
}

.displayForm {
	font-style: italic;
}

.meaning {
	padding-left: 2%;
}

.prevNext {
	text-align:right;
  margin: 10px;
}

.paragraphLinks {
	word-wrap: break-word;
	display: inline-block;
}

.paragraphLink {
	margin: 5px;
	font-size: 1.3em;
}
